import { ref } from '@vue/composition-api';
import toast from '@/utils/toast';
import store from '@/store';
import { useUtils as useI18nUtils } from '@core/libs/i18n';

export default function useContractFinder(props, emit, refFormObserver) {
  const toastification = toast();

  const refForm = ref(refFormObserver);
  const blankItem = {
    phone: '',
    contractNumber: '',
    secretCode: '',
  };
  const itemLocal = ref(JSON.parse(JSON.stringify(blankItem)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(blankItem));
  };
  const { t } = useI18nUtils();
  const onSubmit = () => {
    store.dispatch('contract/findContract', itemLocal.value).then(() => {
      window.location.href = `/sign-contract?phone=${itemLocal.value.phone}&contractNumber=${itemLocal.value.contractNumber}&secretCode=${itemLocal.value.secretCode}`;
    }).catch(error => {
      toastification.showToastError(error);
    });
  };

  return {
    refForm,
    itemLocal,
    resetItemLocal,
    onSubmit,
    t,
  };
}
