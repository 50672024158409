<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <brand />

        <b-card-title class="mb-1">
          {{ t('Tra cứu & ký hợp đồng điện tử') }}! 👋
        </b-card-title>
        <b-card-text class="mb-2">
          {{ t('Vui lòng cung cấp đầy đủ thông tin bên dưới để tiến hành ký hợp đồng điện tử') }}!
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="form"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="onSubmit"
          >

            <!-- phone -->
            <b-form-group
              label-for="phone"
            >
              <template
                v-slot:label
              >
                {{ t('Số điện thoại') }}
                <span
                  class="text-danger"
                >
                  (*)
                </span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Số điện thoại"
                rules="required"
              >
                <b-form-input
                  id="phone"
                  v-model="itemLocal.phone"
                  name="phone"
                  :state="errors.length > 0 ? false:null"
                  placeholder="0868987355"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- phone -->
            <b-form-group
              label-for="contractNumber"
            >
              <template
                v-slot:label
              >
                {{ t('Mã hợp đồng') }}
                <span
                  class="text-danger"
                >
                  (*)
                </span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Mã hợp đồng"
                rules="required"
              >
                <b-form-input
                  id="contractNumber"
                  v-model="itemLocal.contractNumber"
                  name="contractNumber"
                  :state="errors.length > 0 ? false:null"
                  placeholder="HD000001"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- phone -->
            <b-form-group>
              <template
                v-slot:label
              >
                {{ t('Mã bảo mật') }}
                <span
                  class="text-danger"
                >
                  (*)
                </span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Mã bảo mật"
                rules="required"
              >
                <b-form-input
                  id="secretCode"
                  v-model="itemLocal.secretCode"
                  name="secretCode"
                  :state="errors.length > 0 ? false:null"
                  placeholder="68686868"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid"
            >
              {{ t('Tra cứu') }}
            </b-button>
          </b-form>
        </validation-observer>

      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BCardTitle, BCardText,
} from 'bootstrap-vue';
import { required } from '@validations';
import Brand from '@/layouts/components/Brand.vue';
import useContractFinder from './useContractFinder';

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    Brand,
    BCardText,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      // validation rules
      required,
    };
  },

  setup() {
    const { itemLocal, onSubmit, t } = useContractFinder();

    return {
      itemLocal,
      onSubmit,
      t,
    };
  },

};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
